<template>
  <div>
    <TopNav v-bind:showCart=false v-bind:showLearnMore=true />
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-6">
          <h1 class="mt-5">Sam and the Brand Store is now called Framme.</h1>
          <h3 class="mt-2">Continue to your own store to manage, order and distribute your physical brand assets.</h3>
          <div class="row position-relative">
            <div v-show="updating">
              <div class="loader-container">
                <div class="row justify-content-center">
                  <div class="col-md-4 d-flex justify-content-center">
                    <div class="loader">Loading...</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <label for="email">Email address</label>
              <input id="email" class="inputfield form-control" type="text" autofocus ref="usernameInput" :class="{ 'form-input-error': $v.username.$error}" v-model.trim="username" v-on:blur="$v.username.$touch()">
              <div v-if="$v.username.$error">
                <div class="form-field-error" v-if="!$v.username.required">This field is required</div>
                <div class="form-field-error" v-if="!$v.username.email">Please check your email address.</div>
              </div>
              <div class="form-field-error" v-if="noMatch">
                Oops! Looks like the username and password don't match.
              </div>
              <label for="password">Password</label>
              <input id="password" v-on:keyup.enter="login()" class="inputfield form-control" type="password" :class="{ 'form-input-error': $v.password.$error}" v-model.trim="password" v-on:blur="$v.password.$touch()">
              <div v-if="$v.password.$error">
                <div class="form-field-error" v-if="!$v.password.required">This field is required</div>
              </div>
              <div class="form-field-error" v-if="noMatch">
                Oops! Looks like the username and password don't match.
              </div>
              <button id="loginbtn" class="btn btn-lg btn-primary btn-block" v-on:click="login()">Login</button>
              <div class="d-flex flex-row justify-content keep-me-in mt-2">
                <div class="custom-control custom-checkbox flex-fill">
                  <input type="checkbox" class="custom-control-input" id="customControlInline">
                  <label class="custom-control-label" for="customControlInline">Keep me logged in</label>
                </div>
                <div class="flex-fill text-right">
                  <router-link to="/login/forgot-password">Forgot password?</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <img class="img-width" alt="Framme Store" src="@/assets/images/framme-store-demo.jpg" />
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios'
import TopNav from '../components/TopNav.vue'
import Footer from '../components/Footer.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'landing',
  components: {
    TopNav,
    Footer
  },
  data() {
    return {
      username: null,
      password: null,
      noMatch: false,

      updating: false,
    }
  },
  validations: {
    username: {
      required,
      email
    },
    password: {
      required
    }
  },
  methods:{
    login(){
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.updating = true;
      const vm = this;
      const data = {
        'username': this.username,
        'password': this.password
      }
      this.$store.dispatch('login', data)
      .then(function (response) {
        let redirectPath = null;
        if(response.data.stores.length == 0) {
          vm.$store.dispatch('logout')
          redirectPath = '/no-active-store';
        } else if (vm.$route.params.storename){
          if (response.data.stores.find(s => s.path == vm.$route.params.storename)) {
            redirectPath = "/" + vm.$route.params.storename;
          } else {
            redirectPath = '/' + response.data.stores[0].path;
          }
        } else if(response.data.stores.length > 0) {
          if(vm.$route.query && vm.$route.query.next){
            redirectPath = vm.$route.query.next;
          } else {
            const default_store = response.data.stores.find(s => s.default_store);
            const storePath = default_store ? default_store.path : response.data.stores[0].path
            redirectPath = "/" + storePath
          }
        } else {
          // No allowed stores
          redirectPath = '/login';
          return;
        }
        // REMOVE WITH NEW UI OR WHEN BIOTEL CONTRACT HAS ENDED
        if(redirectPath === '/biotel') {
          window.location.href = 'https://old.framme.com/biotel';
        } else {
          vm.$router.push(redirectPath);
          vm.updating = false;
        }
      })
      .catch(function (error) {
        if (error.response && error.response.data){
          vm.$toastr.e(
            error.response.data.error
          );
        }
        vm.updating = false;
        document.body.style.cursor='default';
      })
    }
  }
}
</script>
